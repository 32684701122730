import { apiv1 } from "@/request/apiv1";
import qs from "qs";

// 获取所有的区域数据报表
export function GetClientsStatisticsAreas(params={}) {
    return new Promise((resolve, reject) => {
        apiv1.get(`/clients/statistics/areas?${qs.stringify(params, {
            arrayFormat: "repeat"
        })}`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 获取所有的基于省份的数据报表
export function GetClientsStatisticsProvinces(params={}) {
    return new Promise((resolve, reject) => {
        apiv1.get(`/clients/statistics/provinces?${qs.stringify(params, {
            arrayFormat: "repeat"
        })}`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}